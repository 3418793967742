import React, { useRef } from 'react';
import useReadingTime from 'use-reading-time';
import { Blockquote } from 'patterns/Blockquote';
import { Content } from 'patterns/Content';
import { MastheadArticle } from 'patterns/MastheadArticle';
import { Shell } from 'patterns/Shell';

import '../../scss/main.scss';

function ArticlePage3({
    location: {
        pathname,
    },
}) {
    const article = useRef();
    const { readingTime } = useReadingTime(article);

    return (
        <Shell
            pageDescription="£0-£100,000 in 17 months freelancing, a look at how my thoughts have evolved over that period"
            pageSocialImage="https://images.prismic.io/robsimpson/1d4d3071-51a9-424c-972e-695ad7a0e6ff_100k-freelance-social-share.jpg?auto=compress,format"
            pageTitle="Reflecting on making my first £100k from freelance projects"
            pathname={pathname}
        >
            <MastheadArticle
                authorImage={{
                    alt: '',
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: 'https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format'
                }}
                authorName="Rob Simpson"
                date="14 Feb, 2022"
                image={{
                    alt: '',
                    dimensions: {
                        height: 1024,
                        width: 768,
                    },
                    url: 'https://images.prismic.io/robsimpson/c3163337-e90c-4881-9139-8ab99f24e45d_100k-freelance.png?auto=compress,format'
                }}
                readTime={readingTime}
                title="Reflecting on making my first £100k from freelance projects"
            />
            <div className="container" ref={article}>
                <Content
                    content={[
                        {spans: [], text: "It’s taken me 17 months to earn £100,000 from freelance projects.", type: "paragraph"},
                        {spans: [], text: "To some, this number might sound impossible to attain and others might wonder why it took so long.", type: "paragraph"},
                        {spans: [], text: "But what did 17 months of freelancing look like?", type: "paragraph"},
                        {spans: [], text: "🥵 The first year of freelancing I was booked flat out, working 5 days a week, some evenings and some weekends", type: "list-item"},
                        {spans: [], text: "😊 The last ~4 months, I’ve not taken on any new clients and have been working 4-day work-weeks (keeping the extra day for my projects)", type: "list-item"},
                        {spans: [], text: "🧭 Why did I take this u-turn?", type: "heading2"},
                        {spans: [], text: "The truth is, I never went into freelancing to be rushed off my feet.", type: "paragraph"},
                        {spans: [], text: "Did I want to earn more money than I was earning in my previous job? Of course.", type: "paragraph"},
                        {spans: [], text: "Ultimately, my goal was freedom, I wanted to enjoy the small things;", type: "paragraph"},
                        {spans: [], text: "💇🏻‍♂️ be able to get my haircut on a Friday afternoon", type: "list-item"},
                        {spans: [], text: "🪁 get to take my daughter to the park in summer", type: "list-item"},
                        {spans: [], text: "🤩 work on my projects which I found most enjoyable", type: "list-item"},
                        {spans: [], text: "But I also didn’t go into freelancing expecting this to be the case right off the bat. I thought I’d have to work harder than I ever had, to make my freelance business succeed and so that’s what I did.", type: "paragraph"},
                        {spans: [], text: "🏔 I could’ve kept going...", type: "heading2"},
                        {spans: [], text: "There came a point though, where I questioned why I was pushing myself so hard... part of me worried I’d lose it all if I didn’t.", type: "paragraph"},
                        {spans: [], text: "As if, all the leads would just dry up and no one would want to work with me anymore, however, I’d proven in the first year that people were really happy with me and the work I had done.", type: "paragraph"},
                        {spans: [{
                            start: 13,
                            end: 19,
                            type: "em",
                        }], text: "I also didn’t need all the extra money.", type: "paragraph"},
                        {spans: [], text: "Sure I could use it for investments or to buy more stuff that I don’t need, but at the expense of enjoying more of what life has to offer? It didn’t seem worth it.", type: "paragraph"},
                        {spans: [], text: "🕵️ Would it have been wrong to carry on like the first year?", type: "heading2"},
                        {spans: [], text: "Not at all. 5 years ago I probably would have.", type: "paragraph"},
                        {spans: [], text: "All I wanted when I started in the design/development space was to grow my own freelance business.", type: "paragraph"},
                        {spans: [], text: "But ~8 years in now, I’ve worked on enough client projects to realise there is more enjoyment out there to be had for myself.", type: "paragraph"},
                        {spans: [], text: "🗺 Where to from here?", type: "heading2"},
                        {spans: [], text: "I’m pretty happy with the way things are right now. I plan to stick to my 4 day work week as much as possible, trying to work on the most exciting and value aligning projects as I can.", type: "paragraph"},
                        {spans: [{
                            start: 72,
                            end: 79,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.stryve.life/",
                            },
                        }], text: "Outside of that, I’ll carry on with my side projects, like this site and Stryve – exploring more wider-reaching topics like health, business and life in general.", type: "paragraph"},
                    ]}
                />
            </div>
        </Shell>
    );
}

export default ArticlePage3;
